module.exports = {
  title: '價格資料',
  list: {
    ui: {
      fields: [
        { path: 'priceRangeRef', width: 150 },
        { path: 'productRef', width: 150 },
        { path: 'pricebookRef', width: 250 },
        { path: 'price' },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['priceRangeRef'],
          ['productRef'],
          ['pricebookRef'],
          ['price'],
        ],
      },
    ],
  },
}
