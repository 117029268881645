module.exports = {
  companies: require('./companies'),
  brands: require('./brands'),
  salesRegions: require('./salesRegions'),
  lensRefractions: require('./lensRefractions'),
  lensDesigns: require('./lensDesigns'),
  lensProgDists: require('./lensProgDists'),
  lensBlanks: require('./lensBlanks'),
  lensCoatings: require('./lensCoatings'),
  staffers: require('./staffers'),
  customerGroups: require('./customerGroups'),
  customers: require('./customers'),
  suppliers: require('./suppliers'),
  products: require('./products'),
  externalProducts: require('./externalProducts'),
}
