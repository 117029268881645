const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const schema = require('../../../utils/schema')

const purchaseOrders = require('./purchaseOrders')
const stockInLines = require('./stockInLines')


const adaptClientModel = R.over(R.lensProp('schema'), schema.adaptClient)
const config = {
  models: {
    purchaseOrders: adaptClientModel(purchaseOrders),
    stockInLines: adaptClientModel(stockInLines),
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
