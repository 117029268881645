const mergeDeep = require('coact/utils/mergeDeep')

const priceSchema = require('../../dataAccess/prices/schema')


const viewSchema = {
  contents: {
    createdAt: { type: 'Date', label: '建立時間' },
    updatedAt: { type: 'Date', label: '修改時間' },
  },
}

const schema = mergeDeep(priceSchema, viewSchema)

module.exports = schema
