const mergeConfig = require('coact/config/mergeConfig')

const salesModel = require('./db/sales')
const salesItemsModel = require('./db/salesItems')

const itemsModelPageConfig = require('./itemsView/modelPageConfig')

const userView = require('./userView')
const batchView = require('./batchView/clientConfig')


const itemSchemaToAppend = {
  schema: {
    contents: {
      orderRef: salesModel.schema,
    },
  },
}

const config = {
  models: {
    sales: {
      ...salesModel,
      views: {
        user: userView,
        batch: batchView,
      },
    },
    salesItems: {
      ...mergeConfig(salesItemsModel, itemSchemaToAppend),
      ...itemsModelPageConfig,
    },
  },
}


module.exports = function salesInstallClient (fullConfig) {
  return mergeConfig(fullConfig, config)
}
