module.exports = {
  title: '客戶資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        name: '客戶編碼',
        unique: true,
      },
      shortName: {
        type: 'String',
        name: '簡稱',
      },
      email: {
        type: 'String',
        name: 'email',
      },
      fax: {
        type: 'String',
        name: '傳真',
      },
      phone: {
        type: 'String',
        name: '電話',
      },
      name: {
        type: 'String',
        name: '名稱',
      },
      invoiceTitle: {
        type: 'String',
        name: '發票抬頭',
      },
      taxId: {
        type: 'String',
        name: '統編',
      },
      zip: {
        type: 'String',
        name: '郵遞區號',
      },
      address: {
        type: 'String',
        name: '公司地址',
      },
      owner: {
        type: 'String',
        name: '負責人',
      },
      contact: {
        type: 'String',
        name: '聯絡人',
      },
      type: {
        type: 'String',
        name: '類別',
        options: ['連鎖', '一般', '現銷'],
      },
      groupRef: {
        type: 'ObjectId',
        reference: 'customerGroups',
        refKeys: ['key'],
        name: '群組',
      },
      salesRegionRef: {
        type: 'ObjectId',
        reference: 'salesRegions',
        refKeys: ['key', 'name'],
        name: '區域名稱',
      },
      deliveryNote: {
        type: 'String',
        name: '交貨備註',
      },
      salesPersonRef: {
        type: 'ObjectId',
        reference: 'staffers',
        refKeys: ['name'],
        name: '業務代表',
      },
      printPrice: {
        type: 'Boolean',
        name: '列印售價',
      },
      '@suspended': {
        type: 'Boolean',
        label: '停業',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 100 },
        { path: 'shortName', width: 100 },
        { path: 'type', width: 60 },
        { path: 'groupRef', width: 65 },
        { path: 'contact', width: 90 },
        { path: 'phone', width: 130 },
        { path: 'fax', width: 130 },
        { path: 'address', width: 200 },
        { path: 'salesPersonRef', width: 85 },
        { path: '@suspended', width: 60 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        title: '系統資料',
        cols: { default: 350 },
        fields: [
          ['key'],
        ],
      },
      {
        name: 'formEditor',
        title: '基本客戶資料',
        cols: { default: 350 },
        fields: [
          ['name', '@suspended'],
          ['shortName', 'email'],
          ['fax', 'phone'],
        ],
      },
      {
        name: 'formEditor',
        title: '發票資料',
        cols: { default: 350 },
        fields: [
          ['invoiceTitle', 'taxId'],
          ['zip', 'address'],
          ['owner', 'contact'],
        ],
      },
      {
        name: 'formEditor',
        title: '業務管理資料',
        cols: { default: 350 },
        fields: [
          ['type', 'groupRef'],
          ['salesRegionRef', 'deliveryNote'],
          ['salesPersonRef', 'printPrice'],
        ],
      },
    ],
  },
}
