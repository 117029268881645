import React from 'react'
import { func } from 'prop-types'

import schemaAt from 'coact/utils/schema/schemaAt'
import QueryEditor from 'coact/pages/ListPage/QueryEditor'

import Button from '@material-ui/core/Button'

const getQueryEditorProps = props => ({
  getFilterProps: filter => ({
    value: filter,
    onRemove: () => props.removeFilterPath(filter.path),
    schema: schemaAt(filter.path, props.schema),
    queryData: () => {},
  }),
  queryData: () => {},
})


function Filter (props) {
  return (
    <div>
      <QueryEditor {...getQueryEditorProps(props)} />
      <Button variant="contained" color="primary" onClick={props.download}>
        下載報表
      </Button>
      <Button variant="contained" color="primary" onClick={props.batchDownload}>
        批次下載門市報表
      </Button>
      <Button variant="contained" color="primary" onClick={props.closeSalesOrders}>
        銷貨單批次關帳
      </Button>
    </div>
  )
}
Filter.propTypes = {
  download: func,
  batchDownload: func,
  closeSalesOrders: func,
}

export default Filter
