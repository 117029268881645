const R = require('ramda')

module.exports = {
  '': [
    {
      src: 'buyerRef',
      fill: (buyerRef) => {
        const {
          _id, key, name, shortName, phone, zip, address, deliveryNote, printPrice,
          groupRef: customerGroupRef,
          salesRegionRef: salesRegion,
          salesPersonRef: salesPerson,
        } = buyerRef

        const buyer = {
          // eslint-disable-next-line object-property-newline
          _id, key, name, shortName, phone, zip, address, deliveryNote, printPrice,
        }

        return {
          buyer,
          customerGroupRef,
          customerGroup: customerGroupRef,
          salesRegion,
          salesPerson,
        }
      },
    },
    {
      src: 'brandRef',
      fill: (brandRef) => {
        const brand = R.pick(['_id', 'key', 'name'], brandRef)

        return { brand }
      },
    },
    {
      src: 'stafferRef',
      fill: (stafferRef) => {
        const staffer = R.pick(['_id', 'key', 'name'], stafferRef)

        const { companyRef } = stafferRef

        return {
          staffer,
          sellerRef: companyRef,
          orderStafferRef: stafferRef,
        }
      },
    },
    {
      src: 'sellerRef',
      fill: (sellerRef) => {
        const seller = R.pick(['_id', 'key', 'name'], sellerRef)

        return { seller }
      },
    },
    {
      src: 'orderStafferRef',
      fill: (orderStafferRef) => {
        const orderStaffer = R.pick(['_id', 'key', 'name'], orderStafferRef)

        return { orderStaffer }
      },
    },
  ],
  'items.0': {
    src: 'productRef',
    fill: [
      { from: '', to: 'product', or: null },
    ],
  },
}
