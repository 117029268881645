module.exports = {
  title: '品牌資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        name: '品牌編號',
      },
      name: {
        type: 'String',
        name: '品牌名稱',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key' },
        { path: 'name' },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }],
          [{ path: 'name' }],
        ],
      },
    ],
  },
}
