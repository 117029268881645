import compose from 'recompose/compose'

import withHandlers from 'recompose/withHandlers'

import { connect } from 'react-redux'
import * as $editor from 'coact/store/editor'

import download from 'downloadjs'

import moment from 'moment'


function appendQueryStr (url, query) {
  if (!query) return url

  const querys = []
  Object.keys(query).forEach(key => {
    if (query[key] === undefined) return
    querys.push(`${key}=${encodeURIComponent(JSON.stringify(query[key]))}`)
  })

  return `${url}?${querys.join('&')}`
}

const config = {
  BAO_DAO: { route: 'exportBaoDao', name: '寶島出貨' },
  BAO_DAO_PROCESS: { route: 'exportBaoDaoProcess', name: '寶島加工' },
  XIAO_LIN: { route: 'exportXiaoLin', name: '小林銷售' },
  XIAO_LIN_CONSIGNMENT: { route: 'exportXiaoLinConsignment', name: '小林寄賣' },
}

export default compose(
  connect(state => ({
    state: $editor.select.value(state)
  })),

  withHandlers({
    onDownload: ({ state: { exportDate } }) => async (configKey) => {
      const { route, name } = config[configKey]

      const url = appendQueryStr(`api/route/${route}`, { exportDate })
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
      })

      if (!response.ok) {
        const { message } = await response.json()
        // eslint-disable-next-line no-alert
        alert(`錯誤: ${message}`)
        return
      }

      const data = await response.blob()
      download(data, `${moment(exportDate).format('YYYYMMDD')}_${name}.txt`)
    },
  })
)
