module.exports = {
  title: '商品資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        unique: true,
        name: '商品代碼',
      },
      name: {
        type: 'String',
        name: '商品名稱',
      },
      type: {
        type: 'String',
        name: '商品類別',
        options: ['A', 'B', 'C', 'D', 'E'], // A: 鏡框, B: 鏡片, C: 隱形眼鏡, D: 藥水, E: 其他
      },
      state: {
        type: 'String',
        name: '狀態',
        options: ['銷售', '停售'],
      },
      // ---------- 片種使用欄位 ----------
      lensRefractionRef: {
        type: 'ObjectId',
        reference: 'lensRefractions',
        name: '折射率',
        refKeys: ['key'],
        input: 'select',
      },
      lensDesignRef: {
        type: 'ObjectId',
        reference: 'lensDesigns',
        name: '鏡片設計',
        refKeys: ['key'],
        input: 'select',
      },
      lensProgDistRef: {
        type: 'ObjectId',
        reference: 'lensProgDists',
        name: '累進帶',
        refKeys: ['key'],
        input: 'select',
      },
      lensBlankRef: {
        type: 'ObjectId',
        reference: 'lensBlanks',
        name: '毛胚',
        refKeys: ['key'],
        input: 'select',
      },
      lensCoatingRef: {
        type: 'ObjectId',
        reference: 'lensCoatings',
        name: '多層膜',
        refKeys: ['key'],
        input: 'select',
      },
      scope: {
        type: 'Object', // { sMax: 1000, sMin: -2000, cMax: 0, cMin: -1000 }
        name: '度數範圍',
      },
      processType: {
        type: 'String',
        name: '加工/代工',
        options: ['加工', '代工'],
      },
      // ------------------------------
      note: {
        type: 'String',
        name: '備註',
        input: 'textarea',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 100 },
        { path: 'name', width: 200 },
        { path: 'type' },
        { path: 'lensRefractionRef' },
        { path: 'lensDesignRef', width: 120 },
        { path: 'lensProgDistRef' },
        { path: 'lensBlankRef', width: 120 },
        { path: 'lensCoatingRef' },
        { path: 'note' },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        // cols: { default: 500 },
        fields: [
          [{ path: 'key' }, { path: 'type' }, { path: 'state' }],
          [{ path: 'name', span: 2 }],
          [{ path: 'lensRefractionRef' }, { path: 'lensDesignRef' }],
          [{ path: 'lensProgDistRef' }, { path: 'lensBlankRef' }],
          [{ path: 'lensCoatingRef' }, { path: 'processType' }],
          [{ path: 'note', span: 2 }],
        ],
      },
    ],
  },
  export: {
    populations: 'lensRefractions,lensDesigns,lensProgDists,lensBlanks,lensCoatings',
    populationdepth: 1,
    fields: [
      '_id',
      'key',
      'name',
      'type',
      'lensRefractionRef',
      'lensDesignRef',
      'lensProgDistRef',
      'lensBlankRef',
      'lensCoatingRef',
      'processType',
      'note',
    ],
  },
}
