module.exports = {
  title: '銷貨項目',
  schema: {
    type: 'Object',
    contents: {
      orderRef: {
        type: 'ObjectId',
        reference: 'sales',
        view: 'user',
        refKeys: ['key'],
        label: '銷貨單',
      },
      productRef: {
        type: 'ObjectId',
        label: '品名',
        reference: 'products',
        refKeys: ['key', 'name'],
      },
      product: {
        type: 'Object',
        contents: {
          _id: { type: 'ObjectId' },
          type: { type: 'String' },
          key: { type: 'String' },
          name: { type: 'String' },
          processType: { type: 'String' },
        },
      },
      spec: {
        type: 'String',
        label: '規格',
      },
      lensSpec: {
        label: '鏡片規格',
        type: 'Object',
        contents: {
          eye: {
            type: 'String',
            label: 'LR',
            options: ['L', 'R'],
          },
          s: {
            // 3=3.00; 3.5 = 3.50; -.5 = -0.50
            type: 'Number',
            label: 'S',
            format: '-0.00',
            // validator: degreeValidators.s,
          },
          c: {
            //  .75 =  -0.75 ; -.75 = -0.75 只有負值
            type: 'Number',
            label: 'C',
            format: '-0.00',
            parser: function cParser (value) {
              const num = +value
              return (num > 0 ? -num : num)
            },
            // validator: degreeValidators.c,
          },
          a: {
            // a 0 ~ 180, 正整數
            type: 'Number',
            label: 'A',
            // validator: degreeValidators.a,
          },
          add: {
            // 如何輸入 ADD 2=2.00 只有正值
            type: 'Number',
            label: 'ADD',
            format: '-0.00',
            // validator: degreeValidators.add,
          },
        },
      },
      priceRange: {
        type: 'Object',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
          note: { type: 'String' },
        },
      },
      pricebook: {
        type: 'Object',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
        },
      },
      unitPrice: { // fixedPrice
        type: 'Number',
        label: '單價',
      },
      bookPrice: {
        type: 'Number', label: '表價',
      },
      price: {
        type: 'Number', label: '售價',
      },
      qty: { type: 'Number', label: '數量' },
      subtotal: { type: 'Number', label: '小計' },
      note: { type: 'String', label: '項目備註' },
      discount: {
        type: 'Number',
        label: '折數',
        parser: function discountParser (value) {
          if (!value) return null
          if (+value > 1) return +`0.${value.match(/\d+/g).join('')}`
          return +value
        },
      },
    },
  },
  index: {
    createdAt: {
      key: { '@@createdAt': 1 },
    },
    order: {
      key: { orderRef: 1, '@@createdAt': -1 },
    },
  },
}
