const R = require('ramda')

const assocPath = require('coact/utils/assocPath')

const mergeClientRequiredChanges = require('./mergeClientRequiredChanges')
const mergeSystemFields = require('./mergeSystemFields')

const sales = require('../db/sales')
const salesItems = require('../db/salesItems')


const itemSchema = R.pipe(
  mergeSystemFields,
  mergeClientRequiredChanges,
)(salesItems.schema)

const salesSchema = R.pipe(
  mergeSystemFields,
  mergeClientRequiredChanges,
)(sales.schema)

const schema = R.pipe(
  assocPath(['contents', 'items', 'contents', 0], itemSchema),
)(mergeClientRequiredChanges(salesSchema))


module.exports = schema
