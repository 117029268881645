const schema = require('./schema')
const listPage = require('./listPage')
const editPage = require('./editPage')

const config = {
  title: '盤點庫存上傳',
  schema,
  list: listPage,
  edit: editPage,
}

module.exports = config
