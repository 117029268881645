const mergeConfig = require('coact/config/mergeConfig')

const SalesReport = require('./SalesReport')


const config = {
  routes: {
    salesReport: {
      title: '銷貨單報表下載',
      path: '/export/salesReport',
      component: SalesReport,
      authChecker: ({ prints: { salesReport } }) => salesReport && salesReport.get,
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
