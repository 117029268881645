module.exports = {
  ui: {
    order: {
      name: 'formEditor',
      fields: [
        ['key', 'date', 'status'],
        ['staffer', 'buyer'],
        ['seller.name', 'seller.phone', 'seller.address'],
        [{ path: 'sellerRef', span: 2 }, 'keyOfSellerSide', 'billDate'],
        ['orderType', 'amount', 'summary'],
        [{ path: 'note', multiline: true, span: 4 }],
        [{ path: 'productRef', span: 2 }, 'itemsFile'],
      ],
    },
  },
  disabled: {
    key: true,
    date: true,
    staffer: true,
    buyer: true,
    'seller.name': true,
    'seller.phone': true,
    'seller.address': true,
    amount: true,
    summary: true,
  },
}
