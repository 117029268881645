module.exports = {
  title: '累進帶資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        unique: true,
        name: '累進帶代碼',
      },
      distance: {
        type: 'String',
        name: '累進帶距離',
      },
    },
  },
  index: {
    primary: {
      key: {
        distance: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 100 },
        { path: 'distance', width: 100 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }],
          [{ path: 'distance' }],
        ],
      },
    ],
  },
}
