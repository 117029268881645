const moment = require('moment')

module.exports = function checkBillDate (doc) {
  const { date, billDate } = doc

  const d = moment(date)
  const b = moment(billDate)

  if (d.startOf('day').isAfter(b.startOf('day'))) {
    return {
      ok: false,
      error: {
        message: '結帳日期不可小於開單日期',
      },
    }
  }

  if (b.diff(d, 'months', true) > 3) {
    return {
      ok: false,
      alert: {
        message: '結帳日期大於開單日期三個月，確定儲存？',
      },
    }
  }

  return { ok: true }
}
