const schema = require('./schema')

module.exports = {
  title: '進貨項目',
  schema,
  list: {
    ui: {
      fields: [
        { path: 'orderRef.key', model: 'purchaseOrders', queryPath: 'orderRef' },
        { path: 'orderRef.date', model: 'purchaseOrders', queryPath: 'orderRef' },
        { path: 'orderRef.sellerRef', model: 'purchaseOrders', queryPath: 'orderRef' },
        'productRef',
        'lensSpec.s',
        'lensSpec.c',
        'lensSpec.add',
        'qty',
        'unitPrice',
        'note',
      ],
    },
    populationdepth: 2,
    populations: 'purchaseOrders,products,suppliers',
  },
  edit: {
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['productRef'],
          ['lensSpec.s', 'lensSpec.c', 'lensSpec.add'],
          ['qty', 'unitPrice', 'note'],
        ],
      },
    },
  },
}
