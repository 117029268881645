module.exports = {
  ui: {
    fields: [
      'key', 'custOrderNo', 'stafferRef', 'date', 'billDate',
      'customerGroupRef', 'buyerRef', 'brandRef', 'seller',
      'orderType', 'summary', 'status', 'paymentStatus',
    ],
  },
  populations: 'staffers,customers,brands,customerGroups,companies',
}
