const R = require('ramda')

const installGeneralView = require('./generalView/installClient')
const installBatchView = require('./batchView/installClient')
const installLineView = require('./lineView/installClient')


module.exports = R.pipe(
  installGeneralView,
  installBatchView,
  installLineView,
)
