const Editor = require('./Editor').default

module.exports = {
  Components: {
    PageForm: Editor,
  },
  ui: {
    order: {
      name: 'formEditor',
      fields: [
        ['key', 'date', 'status'],
        ['staffer', 'buyer'],
        ['seller.name', 'seller.phone', 'seller.address'],
        [{ path: 'sellerRef', span: 2 }, 'keyOfSellerSide', 'billDate'],
        ['orderType', 'amount', 'summary'],
        [{ path: 'note', multiline: true, span: 4 }],
      ],
    },
    items: {
      name: 'tableEditor',
      path: 'lines',
      // limit: 14,
      fields: [
        { path: 'productRef', width: 300, sort: null },
        { path: 'lensSpec.eye', width: 60 },
        { path: 'lensSpec.s', width: 60, inputType: 'number', step: 0.25 },
        { path: 'lensSpec.c', width: 60, inputType: 'number', step: 0.25 },
        { path: 'lensSpec.a', width: 50, inputType: 'number' },
        { path: 'lensSpec.add', width: 60, inputType: 'number', step: 0.25 },
        { path: 'spec', width: 290, span: 5 },
        { path: 'unitPrice', width: 50 },
        { path: 'qty', width: 50 },
        { path: 'subtotal', width: 50 },
        // { path: 'discount', width: 50, tabIndex: -1 },
        { path: 'note', width: 100 },
      ],
      deletable: true,
      copyTabIndex: 0,
    },
  },
  disabled: {
    key: true,
    date: true,
    staffer: true,
    buyer: true,
    'seller.name': true,
    'seller.phone': true,
    'seller.address': true,
    amount: true,
    summary: true,
    'lines.0.subtotal': true,
    'lines.0.unitPrice': true,
  },
}
