import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'

import FormEditor from 'coact/editors/FormEditor'
import TableEditor from 'coact/editors/TableEditor'
import Field from 'coact/editors/Field'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

const hotkeys = [
  { name: '儲存', hotkey: 'Ctrl + Enter' },
  { name: '列印並開新單', hotkey: 'Shift + Enter' },
  { name: '儲存並開新單', hotkey: 'Ctrl + b' },
  { name: '回列表', hotkey: 'Ctrl + q' },
]

const flexContainer = {
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: 0,
  backgroundColor: '#fcf8e3',
  borderRadius: 3,
  width: 'max-content',
  border: '1px solid #faebcc',
  fontSize: 14,
  fontWeight: 'bold',
}
const hotkeyStyle = {
  backgroundColor: 'black',
  color: 'white',
  borderRadius: 3,
  padding: '0 5px',
}

const typeBFieldsIndex = [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11]
const otherFields = [0, 6, 7, 8, 9, 10, 11]
function renderFields (fields, cols, row) {
  const productType = R.path(['value', 'product', 'type'], row)
  const fieldsIndexArr = (productType === 'B') ? typeBFieldsIndex : otherFields
  const displayFields = R.map(index => fields[index], fieldsIndexArr)
  return displayFields.map(f => (
    <td key={f.path} style={{ width: f.width }} colSpan={f.span}>
      <Field path={f.path} field={f} />
    </td>
  ))
}

function SalesEditor (props) {
  const { page: { schema, ui } } = props

  const uiItems = { ...ui.items, renderFields }

  return (
    <React.Fragment>
      <List style={flexContainer}>
        {hotkeys.map(hotkey => (
          <ListItem key={hotkey.name} style={{ width: 'unset' }}>
            <span>{hotkey.name}:　</span>
            <span style={hotkeyStyle}> {hotkey.hotkey} </span>
          </ListItem>
        ))}
      </List>
      <FormEditor ui={ui.order} schema={schema} />
      <TableEditor ui={uiItems} schema={schema} />
    </React.Fragment>
  )
}

const { object } = PropTypes
SalesEditor.propTypes = {
  page: object.isRequired,
}


export default SalesEditor
