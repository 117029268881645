const R = require('ramda')

const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const dataSchema = require('../../entity/dataAccess/schema')


const schema = R.assocPath(
  toSchemaPath('orderRef'),
  dataSchema.header,
  dataSchema.line,
)


module.exports = schema
