module.exports = {
  title: '多層膜資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        unique: true,
        name: '多層膜代碼',
      },
      name: {
        type: 'String',
        name: '多層膜名稱',
      },
      type: {
        type: 'String',
        name: '多層膜類別',
      },
    },
  },
  index: {
    primary: {
      key: {
        name: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 120 },
        { path: 'name', width: 190 },
        { path: 'type', width: 110 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }],
          [{ path: 'name' }],
          [{ path: 'type' }],
        ],
      },
    ],
  },
}
