import React from 'react'
import { object, func } from 'prop-types'

import Editor from 'coact/editors/Editor'
import Field from 'coact/editors/Field'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

const config = [
  { key: 'BAO_DAO', label: '寶島出貨' },
  { key: 'BAO_DAO_PROCESS', label: '寶島加工' },
  { key: 'XIAO_LIN', label: '小林銷售' },
  { key: 'XIAO_LIN_CONSIGNMENT', label: '小林寄賣' },
]

function CustExchanger (props) {
  const { classes, onDownload } = props
  const schema = {
    type: 'Object',
    contents: {
      exportDate: {
        type: 'Date',
        label: '匯出日期',
      },
    },
  }
  return (
    <div>
      <Editor schema={schema} >
        {[
          <Field path="exportDate" key="exportDate" />,
          ...config.map(({ key, label }) => (
            <Button
              key={key}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => onDownload(key)}
            >
              {label}
            </Button>
          ))
        ]}
      </Editor>
    </div>
  )
}
CustExchanger.propTypes = {
  classes: object.isRequired,
  onDownload: func.isRequired,
}

export default withStyles(styles)(CustExchanger)
