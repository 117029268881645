const R = require('ramda')

function safeN (v) {
  return Number.isNaN(+v) ? 0 : +v
}

const unitDict = { A: '支', B: '片' }
const getUnit = ({ type }) => unitDict[type]

module.exports = {
  '': [
    ({ items }) => {
      const amount = R.reduce(
        (acc, { subtotal }) => (acc + subtotal),
        0,
        items || [],
      )
      return { amount }
    },
    ({ items }) => {
      const qtyDict = R.reduce(
        (acc, { qty, productRef }) => {
          if (R.isNil(productRef)) return acc

          const unit = getUnit(productRef)
          if (R.isNil(unit)) return acc

          return { ...acc, [unit]: safeN(acc[unit]) + safeN(qty) }
        },
        { },
        items,
      )

      const summary = R.pipe(
        R.toPairs,
        R.map(([unit, qty]) => `${qty} ${unit}`),
        R.join(' '),
      )(qtyDict)

      return { summary }
    },
    ({ items, orderType }) => {
      if (orderType === '銷售') return { }

      const nextItems = R.map(
        ({ note }) => {
          if (!note || note.search(orderType) === -1) {
            return { note: orderType + (note ? ` ${note}` : '') }
          }
          return { }
        },
        items || [],
      )
      return { items: nextItems }
    },
  ],
  'items.0': (item) => {
    const { qty, unitPrice } = item
    return { subtotal: safeN(qty) * safeN(unitPrice) }
  },
}
