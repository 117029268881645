const ui = require('./ui')
const init = require('./init')
const auto = require('./auto')
const action = require('./action')

module.exports = {
  ...ui,
  ...init,
  ...auto,
  ...action,
}
