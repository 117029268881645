const schemaModule = require('../../../utils/schema')

const dataSchema = require('../entity/dataAccess/schema')


const schema = schemaModule.create(dataSchema.header)

const index = {
  primary: {
    key: { key: 1 },
    unique: true,
  },
  type: {
    key: { type: 1 },
  },
  createdAt: {
    key: { '@@createdAt': 1 },
  },
}


module.exports = {
  schema,
  index,
}
