
module.exports = {
  ui: {
    fields: [
      'key', 'keyOfSellerSide', 'stafferRef', 'date', 'billDate',
      'sellerRef', 'buyerRef', 'product.key', 'orderType', 'summary', 'status',
    ],
  },
  populations: 'staffers,companies,suppliers',
}
