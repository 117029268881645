module.exports = {
  title: '價格表資料',
  list: {
    ui: {
      fields: [
        { path: 'key', width: 200 },
        { path: 'brandRef', width: 120 },
        { path: 'timeStart', width: 120 },
        { path: 'timeEnd', width: 120 },
        { path: 'type', width: 80 },
        { path: 'description', width: 250 },
        { path: 'priority', width: 60 },
        { path: 'taxAdded', width: 80 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key', span: 2 }],
          [{ path: 'brandRef' }, { path: 'customerGroupRefs' }],
          [{ path: 'supplierRefs' }],
          [{ path: 'timeStart' }, { path: 'timeEnd' }],
          [{ path: 'priority' }, { path: 'taxAdded' }],
          [{ path: 'type' }, { path: 'description' }],
        ],
      },
    ],
  },
}
