module.exports = {
  title: '設計資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        unique: true,
        name: '設計代碼',
      },
      name: {
        type: 'String',
        name: '設計名稱',
      },
      type: {
        type: 'String',
        name: '設計類別',
        options: ['單光', '雙光', '漸進遠中近', '漸進室內中距', '漸進近近', '玻璃遠中近', '兒控'],
      },
      method: {
        type: 'String',
        name: '設計方式',
        options: ['外面漸進', '內面漸進', '雙面漸進', '減壓'],
      },
    },
  },
  index: {
    primary: {
      key: {
        name: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 90 },
        { path: 'name', width: 200 },
        { path: 'type', width: 150 },
        { path: 'method', width: 100 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }],
          [{ path: 'name' }],
          [{ path: 'type' }],
          [{ path: 'method' }],
        ],
      },
    ],
  },
}
