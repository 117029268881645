module.exports = {
  title: '片種價格區間資料',
  list: {
    ui: {
      fields: [
        { path: 'key', width: 150 },
        { path: 'productRef', width: 150 },
        { path: 'note', width: 300 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }],
          [{ path: 'productRef' }],
          [{ path: 'note' }],
        ],
      },
    ],
  },
}
