module.exports = {
  title: '供應商資料',
  schema: {
    type: 'Object',
    contents: {
      key: { type: 'String', name: '供應商編碼', unique: true },
      shortName: { type: 'String', name: '簡稱' },
      email: { type: 'String', name: 'email' },
      fax: { type: 'String', name: '傳真' },
      phone: { type: 'String', name: '電話' },
      name: { type: 'String', name: '名稱' },
      invoiceTitle: { type: 'String', name: '發票抬頭' },
      taxId: { type: 'String', name: '統編' },
      zip: { type: 'String', name: '郵遞區號' },
      address: { type: 'String', name: '公司地址' },
      owner: { type: 'String', name: '負責人' },
      contact: { type: 'String', name: '聯絡人' },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 100 },
        { path: 'shortName', width: 100 },
        { path: 'contact', width: 90 },
        { path: 'phone', width: 130 },
        { path: 'fax', width: 130 },
        { path: 'address', width: 200 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 350 },
        fields: [
          ['key'],
          ['shortName', 'email'],
          ['fax', 'phone'],
          ['name'],
          ['invoiceTitle', 'taxId'],
          ['zip', 'address'],
          ['owner', 'contact'],
        ],
      },
    ],
  },
}
