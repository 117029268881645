const mergeDeep = require('coact/utils/mergeDeep')
const buildQueryString = require('coact/utils/api/buildQueryString')
const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const config = {
  actions: {
    saveAndCopy: {
      authChecker: () => false,
    },
    downloadXlsx: {
      text: '下載出貨單',
      effect: { name: 'downloadXlsx' },
      props: { variant: 'contained', color: 'primary' },
      hotKey: 'ctrl+p',
      preventDefault: true,
    },
    saveAndCreate: {
      text: '儲存後開新單',
      hotKey: 'ctrl+b',
    },
  },
  effect: {
    downloadXlsx: function* saveAndPrint (payload, ctx) {
      const savedData = yield ctx.S.call(editEffects.save)
      if (savedData) {
        const { key, buyer } = savedData
        const query = {
          id: savedData._id,
          fullname: `${key}-${buyer.key}-${buyer.name}.xlsx`,
        }
        window.open(
          `api/print/batchSales/xlsx?${buildQueryString(query)}`,
          '_blank',
        )
      }
    },
  },
}

module.exports = function addAction (pageConfig) {
  return mergeDeep(pageConfig, config)
}
