const R = require('ramda')

const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const schemaModule = require('../../../utils/schema')

const dataSchema = require('../entity/dataAccess/schema')


const linkField = {
  type: 'ObjectId',
  label: '進貨單號',
  reference: 'purchaseOrders',
  refKeys: ['key'],
}
const schema = R.pipe(
  R.assocPath(
    toSchemaPath('orderRef'), linkField,
  ),
  schemaModule.create,
)(dataSchema.line)

const index = {
  createdAt: {
    key: { '@@createdAt': 1 },
  },
  order: {
    key: { orderRef: 1, '@@createdAt': -1 },
  },
  lines: {
    key: { orderRef: 1, seq: 1 },
  },
}


module.exports = {
  schema,
  index,
}
