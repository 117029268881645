const R = require('ramda')

module.exports = function setBrandOptions (spec, ctx) {
  const { value } = spec
  const { dispatch, $options } = ctx

  dispatch($options.act.loadOptions({
    optionsKey: 'brands',
    options: R.pathOr([], ['groupRef', 'brandRefs'], value),
    shouldFetchFurther: false,
  }))
}
