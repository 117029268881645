import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'coact/ramda'
import range from 'lodash/range'

import Button from '@material-ui/core/Button'

// import Button from '@material-ui/core/Button'

import Editor from 'coact/editors/Editor'
import Field from 'coact/editors/Field'
// import ItemsEditor from 'coact/editors/ItemsEditor'

const renderStocks = (editor) => {
  const { value = {} } = editor

  const scope = R.pathOr({ }, ['data', 'scope'], value)
  const stocks = R.pathOr({ }, ['data', 'stocks'], value)

  const tableStyle = { borderCollapse: 'collapse', textAlign: 'center' }
  const cellStyle = { border: '1px solid black', width: 50 }

  return (
    <div>
      {/*
        <div>{JSON.stringify(scope)}</div>
        <div>{JSON.stringify(stocks)}</div>
      */}
      <div>
        <table style={tableStyle}>
          <tbody>
            <tr>
              <td>S</td>
              <td>C</td>
              {
                range(scope.cMax - 0.25, scope.cMin - 0.25, -0.25)
                  .map(c => (<td key={c}>{(c % 0.5) ? null : c}</td>))
              }
            </tr>
            {
              range(scope.sMax, scope.sMin - 0.25, -0.25).map(s => (
                <tr key={s}>
                  <td>{(s % 0.5) ? null : s}</td>
                  {range(scope.cMax, scope.cMin - 0.25, -0.25).map(c => (
                    <td key={`${s}-${c}`} style={cellStyle} title={`S:${s}, C:${c}`}>
                      {
                        R.prop('qty', R.find(R.whereEq({ s, c }), stocks))
                      }
                    </td>
                  ))}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

function StockState (props) {
  const { schema, download } = props
  return (
    <Editor schema={schema}>
      {
        editor => (
          <React.Fragment>
            <h2>庫存查詢</h2>
            <div>
              <Field path="productId" />
              <Field path="companyId" />
            </div>
            <Button variant="contained" color="primary" onClick={download}>下載</Button>
            {renderStocks(editor)}
          </React.Fragment>
        )
      }
    </Editor>
  )
}

const { object, func } = PropTypes
StockState.propTypes = {
  schema: object.isRequired,
  download: func.isRequired,
}

export default StockState
