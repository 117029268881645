const R = require('ramda')

const S = require('coact/saga/utils')
const getPaths = require('coact/utils/getPaths')

module.exports = function* findProductPrice ({ item, ctx }) {
  try {
    // product related data
    const [productRef, lensSpec] = getPaths(['productRef', 'lensSpec'], item)

    if (R.isNil(productRef)) return null

    const product = R.pick(['_id', 'key', 'name', 'type'], productRef)

    // pricebook related data
    const [brandRef, customerGroupRef, billDate] = getPaths(
      ['brandRef', 'customerGroupRef', 'billDate'],
      ctx.doc,
    )

    if (R.isNil(brandRef)) return null
    if (R.isNil(customerGroupRef)) return null

    const brand = R.pick(['_id', 'key', 'name'], brandRef)
    const customerGroup = R.pick(['_id', 'key'], customerGroupRef)

    const query = { product, lensSpec, brand, customerGroup, billDate }

    const api = yield S.getContext('api')
    const { checkProductPrice: unitPrice } = yield S.call(
      api.get,
      {
        url: 'api/model/sales/view/user',
        search: { query, checkProductPrice: null },
      },
    )

    return unitPrice
  } catch (error) {
    ctx.sendMessage({ type: 'error', message: error.message })
  }
  return null
}
