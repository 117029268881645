const R = require('ramda')

const autofill = {
  '': [
    {
      src: 'sellerRef',
      fill: [
        { from: '', to: 'seller', or: null },
      ],
    },
  ],
  'lines.0': {
    src: 'productRef',
    fill: [
      { from: '', to: 'product', or: null },
    ],
  },
}

function safeN (v) {
  return Number.isNaN(+v) ? 0 : +v
}

const unitDict = { A: '支', B: '片' }
const getUnit = ({ type }) => unitDict[type]

const computer = {
  '': [
    ({ lines }) => {
      const amount = R.reduce(
        (acc, { subtotal }) => (acc + subtotal),
        0,
        lines || [],
      )
      return { amount }
    },
    ({ lines }) => {
      const qtyDict = R.reduce(
        (acc, { qty, productRef }) => {
          if (R.isNil(productRef)) return acc

          const unit = getUnit(productRef)
          if (R.isNil(unit)) return acc

          return { ...acc, [unit]: safeN(acc[unit]) + safeN(qty) }
        },
        { },
        lines,
      )

      const summary = R.pipe(
        R.toPairs,
        R.map(([unit, qty]) => `${qty} ${unit}`),
        R.join(' '),
      )(qtyDict)

      return { summary }
    },
  ],
  'lines.0': (line) => {
    const { qty, unitPrice } = line
    return { subtotal: safeN(qty) * safeN(unitPrice) }
  },
}

module.exports = {
  autofill,
  computer,
}
