const R = require('ramda')

const addUI = require('./addUI')
const addAuto = require('./addAuto')
const addAction = require('./addAction')

module.exports = R.pipe(
  addUI,
  addAuto,
  addAction,
)({ })
