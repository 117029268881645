module.exports = {
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      label: '價格區間代碼',
      unique: true,
    },
    productRef: {
      type: 'ObjectId',
      reference: 'products',
      refKeys: ['key'],
      label: '商品代碼',
      input: 'select',
    },
    range: {
      type: 'Array', // [ { s: 1000, c: 0 }, ... ]
      label: '價格區間',
    },
    note: {
      type: 'String',
      label: '區間說明',
      input: 'textarea',
    },
  },
}
