const { connect } = require('react-redux')

const { compose, lifecycle, withProps, withHandlers } = require('recompose')

const { withPageContext } = require('coact/pages/Page/PageContext')

const $editor = require('coact/store/editor')


function buildUrl (url, query) {
  if (!query) return url

  const queries = []
  Object.keys(query).forEach((key) => {
    if (query[key] === undefined) return
    queries.push(`${key}=${encodeURIComponent(JSON.stringify(query[key]))}`)
  })

  return `${url}?${queries.join('&')}`
}

const schema = {
  type: 'Object',
  contents: {
    billDate: {
      label: '結帳日期',
      type: 'date',
      format: 'YYYY/MM/DD',
    },
    customerGroupRef: {
      label: '客戶群組',
      type: 'ObjectId',
      reference: 'customerGroups',
      refKeys: ['key'],
      sort: { '@order': -1, key: 1 },
      limit: 2048,
      displayOptionsNumber: 1024,
      closeMenuOnSelect: false,
    },
    buyerRef: {
      label: '客戶門市',
      type: 'ObjectId',
      reference: 'customers',
      refKeys: ['key', 'name'],
      sort: { key: 1 },
      limit: 2048,
      displayOptionsNumber: 1024,
      closeMenuOnSelect: false,
    },
    onlyLens: {
      label: '寶島特殊 (單選)',
      type: 'String',
      options: ['純代工類(無鏡片)', '非純代工類(有鏡片)', '不分類'],
    },
  },
}

const getSelectedId = where => (
  where.selecteds ? where.selecteds.map(d => d._id) : []
)

const container = compose(
  withPageContext,

  lifecycle({
    componentDidMount () {
      this.props.dispatch($editor.act.loadValue({
        filters: [
          { path: 'billDate', type: 'date', label: '結帳日期', where: {} },
          { path: 'customerGroupRef', type: 'select', label: '客戶群組', where: {} },
          { path: 'buyerRef', type: 'select', label: '客戶門市', where: {} },
          { path: 'onlyLens', type: 'select', label: '寶島特殊 (單選)', where: {} },
        ],
      }))
    },
  }),

  withProps(() => ({ schema })),
  connect(state => ({
    query: $editor.select.value(state),
  })),

  withHandlers({
    download: props => () => {
      const { query: { filters } } = props
      const query = {
        billDate: filters.find(f => f.path === 'billDate').where,
        customerGroupRef: getSelectedId(filters.find(f => f.path === 'customerGroupRef').where),
        buyerRef: getSelectedId(filters.find(f => f.path === 'buyerRef').where),
        onlyLens: filters.find(f => f.path === 'onlyLens').where.selecteds,
      }

      const url = buildUrl('api/print/salesReport/xlsx', query)
      window.open(url, '_blank')
    },
    batchDownload: props => () => {
      const { query: { filters } } = props
      const query = {
        billDate: filters.find(f => f.path === 'billDate').where,
        customerGroupRef: getSelectedId(filters.find(f => f.path === 'customerGroupRef').where),
        buyerRef: getSelectedId(filters.find(f => f.path === 'buyerRef').where),
        onlyLens: filters.find(f => f.path === 'onlyLens').where.selecteds,
      }

      const url = buildUrl('api/route/batchDownloadSalesReport', query)
      window.open(url, '_blank')
    },
    closeSalesOrders: props => () => {
      // eslint-disable-next-line no-alert
      if (window.confirm('是否確認關帳')) {
        const { query: { filters } } = props
        const query = {
          billDate: filters.find(f => f.path === 'billDate').where,
          customerGroupRef: getSelectedId(filters.find(f => f.path === 'customerGroupRef').where),
          buyerRef: getSelectedId(filters.find(f => f.path === 'buyerRef').where),
          onlyLens: filters.find(f => f.path === 'onlyLens').where.selecteds,
        }

        const url = buildUrl('api/route/closeSalesOrders', query)
        window.open(url, '_blank')
      }
    },
  }),
)

module.exports = container
