import Upload from './Upload'

export default Upload
// const Upload = require('./Upload')
// module.exports = Upload

/* import compose from 'recompose/compose'
import withState from 'recompose/withState'

import withUploadFile from './withUploadFile'

export default compose(
  withState('templateName', 'setTemplateName', ''),
  withState('file', 'setFile', null),
  withUploadFile
)(Upload) */
