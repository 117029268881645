const S = require('coact/saga/utils')

const findProductPrice = require('../findProductPrice')

const shouldFindPrice = require('./shouldFindPrice')

module.exports = function* watchItemForPrice (spec, ctx) {
  // delay 1000ms
  yield S.delay(1000)

  const { value, prevValue, path } = spec

  if (!shouldFindPrice(value, prevValue)) return

  const unitPrice = yield S.call(findProductPrice, { item: value, ctx })

  yield S.call(ctx.editPath, { path: [...path, 'unitPrice'], value: unitPrice })
}
