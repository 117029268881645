const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    prices: {
      schema: require('./prices/schema'),
      ...require('./prices/ui'),
    },
    priceRanges: {
      schema: require('./priceRanges/schema'),
      ...require('./priceRanges/ui'),
    },
    pricebooks: {
      schema: require('./pricebooks/schema'),
      ...require('./pricebooks/ui'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
