import * as RA from 'ramda-adjunct'
import * as R from 'ramda'

import coact from 'coact'

import models from './models/client'
import menu from './menu'

import SalesOrderPrinter from './components/SalesOrderPrinter'
import CustExchanger from './components/CustExchanger'
import UploadTemplate from './components/UploadTemplate'

import installSales from './sales/installClient'
import installPurchase from './purchase/installClient'
import installPrice from './price/installClient'
import installSalesReport from './salesReport/installClient'
import installStock from './stock/installClient'


const config = {
  title: '立勝光電',
  models,
  routes: {
    custExchanger: {
      title: '下載寶島小林上傳檔',
      path: '/export/exchangeData',
      component: CustExchanger,
      authChecker: (auth) => {
        const permissions = RA.paths(
          [
            ['exportBaoDao', 'get'],
            ['exportBaoDaoProcess', 'get'],
            ['exportXiaoLin', 'get'],
            ['exportXiaoLinConsignment', 'get'],
          ],
          auth.routes,
        )
        return R.reduce(R.and, true, permissions)
      },
    },
    uploadTemplate: {
      title: '匯入 template',
      path: '/upload/template',
      component: UploadTemplate,
    },
  },
  printers: {
    'sales:user': SalesOrderPrinter,
  },
  menu,
  sagas: { },
}

const completeConfig = R.pipe(
  installPrice,
  installSales,
  installPurchase,
  installSalesReport,
  installStock,
)(config)

coact(completeConfig)
