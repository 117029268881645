module.exports = {
  title: '商品對應外部系統',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        name: '資料編碼',
        disabled: true,
        /* 前端自動組合 */
        counter: {
          key: () => 'E',
          value: (key, value) => `${key}${value}`,
        },
      },
      system: {
        type: 'String',
        name: '對應系統',
        required: true,
        options: ['F-寶聯', 'L-小林'],
      },
      brandRef: {
        type: 'ObjectId',
        name: '內部品牌',
        reference: 'brands',
        refKeys: ['key'],
      },
      priceRangeRef: {
        type: 'ObjectId',
        name: '內部價格區間',
        reference: 'priceRanges',
        refKeys: ['key'],
      },
      productRef: {
        type: 'ObjectId',
        name: '內部商品',
        required: true,
        reference: 'products',
        refKeys: ['key'],
      },
      extProductKey: {
        type: 'String',
        name: '外部商品編號',
      },
      extProductName: {
        type: 'String',
        name: '外部商品名稱',
      },
      isConsignment: {
        type: 'Boolean',
        name: '寄賣',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 80 },
        { path: 'system' },
        { path: 'brandRef', width: 80 },
        { path: 'priceRangeRef', width: 110 },
        { path: 'productRef' },
        { path: 'extProductKey', width: 200 },
        { path: 'extProductName', width: 110 },
        { path: 'isConsignment' },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }, { path: 'system' }],
          [{ path: 'brandRef' }, { path: 'isConsignment' }],
          [{ path: 'priceRangeRef' }, { path: 'productRef' }],
          [{ path: 'extProductKey' }, { path: 'extProductName' }],
        ],
      },
    ],
  },
}
