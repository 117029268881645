const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    stockChecks: {
      schema: require('./stockChecks/schema'),
    },
    stockInfos: {
      title: 'stockInfos',
      schema: require('./stockInfos/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
