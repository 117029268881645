const R = require('ramda')
const S = require('coact/saga/utils')

const getPaths = require('coact/utils/getPaths')
const notEqPaths = require('coact/utils/notEqPaths')


function* findProductPrice ({ line, ctx }) {
  try {
    // product related data
    const [productRef, lensSpec] = getPaths(['productRef', 'lensSpec'], line)

    if (R.isNil(productRef)) return null

    const product = R.pick(['_id', 'key', 'name', 'type'], productRef)

    // pricebook related data
    const [seller, billDate] = getPaths(
      ['seller', 'billDate'],
      ctx.doc,
    )

    if (R.isNil(seller)) return null

    const types = ['進貨價']

    const query = { product, lensSpec, seller, billDate, types }

    const api = yield S.getContext('api')
    const { checkProductPrice: unitPrice } = yield S.call(
      api.get,
      {
        url: 'api/model/purchaseOrders/view/general',
        search: { query, checkProductPrice: null },
      },
    )

    return unitPrice
  } catch (error) {
    ctx.sendMessage({ type: 'error', message: error.message })
  }
  return null
}

function shouldFindPrice (line, prevLine) {
  const type = R.path(['productRef', 'type'], line)

  const watched = (
    type === 'B' ? ['productRef', 'lensSpec.s', 'lensSpec.c'] : ['productRef']
  )

  const change = notEqPaths(R.__, line, prevLine)
  if (change(watched)) return true

  return false
}

function* watchLineForPrice (spec, ctx) {
  // delay 1000ms
  yield S.delay(1000)

  const { value, prevValue, path } = spec

  if (!shouldFindPrice(value, prevValue)) return

  const unitPrice = yield S.call(findProductPrice, { line: value, ctx })

  yield S.call(ctx.editPath, { path: [...path, 'unitPrice'], value: unitPrice })
}


module.exports = {
  watcher: {
    'lines.0': { name: 'watchLineForPrice', run: 'latest' },
  },
  actions: {
    saveAndCopy: {
      authChecker: () => false,
    },
    saveAndCreate: {
      text: '儲存後開新單',
      hotKey: 'ctrl+b',
    },
  },
  effect: {
    watchLineForPrice,
  },
}
