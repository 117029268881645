const R = require('ramda')

const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const dataSchema = require('../../entity/dataAccess/schema')


const schema = R.assocPath(
  toSchemaPath('lines'),
  { type: 'Array', contents: [dataSchema.line] },
  dataSchema.header,
)


module.exports = schema
