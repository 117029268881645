const R = require('ramda')

const mergeConfig = require('coact/config/mergeConfig')
const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const priceSchema = require('../../dataAccess/prices/schema')
const productSchema = require('~/src/models/products').schema

const schema = R.assocPath(
  toSchemaPath('productRef'),
  productSchema,
  priceSchema,
)

const wholesalePriceLookupView = {
  title: '批發價查詢',
  schema,
  list: {
    ui: {
      fields: [
        { path: 'priceRangeRef', width: 150 },
        { path: 'productRef.key', model: 'products', queryPath: 'productRef' },
        { path: 'productRef.name', model: 'products', queryPath: 'productRef' },
        { path: 'price' },
        { path: 'pricebookRef', width: 250 },
      ],
    },
    actions: {
      exportData: {
        authChecker: () => false,
      },
    },
  },
}

const config = {
  models: {
    prices: {
      views: {
        wholesale: wholesalePriceLookupView,
      },
    },
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
