const autofill = {
  '': [
    {
      src: 'sellerRef',
      fill: [
        { from: '', to: 'seller', or: null },
      ],
    },
    {
      src: 'productRef',
      fill: [
        { from: '', to: 'product', or: null },
      ],
    },
  ],
}

module.exports = {
  autofill,
}
