const S = require('coact/saga/utils')

const R = require('ramda')

const shallowEqual = require('coact/utils/shallowEqual')
const buildQueryString = require('coact/utils/api/buildQueryString')

const $editor = require('coact/store/editor')

const $editorSaga = require('coact/saga/editorSaga')
const pageAct = require('coact/pages/modules/pageAction/act')

const actions = require('./actions')


const watch = (keys, a, b) => !shallowEqual(R.pick(keys, a), R.pick(keys, b))

function* findStocks (payload) {
  const { value, prevValue } = payload
  const { productId, companyId } = value

  if (!productId || !companyId) return
  if (!watch(['productId', 'companyId'], value, prevValue)) return

  const api = yield S.getContext('api')
  const { data } = yield S.call(
    api.get,
    {
      url: '/api/route/stockState',
      search: {
        query: {
          productId: productId._id,
          companyId: companyId._id,
        },
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: ['data'],
    value: data,
  }))
}

function* download () {
  const { productId, companyId } = yield S.select($editor.select.value)

  const query = {
    query: {
      productId: productId._id,
      companyId: companyId._id,
    },
    fullname: `${productId.key}-${companyId.name}.xlsx`,
    companyName: companyId.name,
    productKey: productId.key,
  }

  window.open(
    `api/print/stockState/xlsx?${buildQueryString(query)}`,
    '_blank',
  )
}

function* handleEffect ({ payload }) {
  const { name } = payload
  if (name === 'findStocks') yield S.call(findStocks, payload)
}

module.exports = function* editStocksSaga () {
  yield S.takeEvery(pageAct.everyEffect, handleEffect)
  yield S.takeFirst(actions.download, download)
}
