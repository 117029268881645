module.exports = {
  title: '客戶群組資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        name: '群組名稱',
        unique: true,
      },
      brandRefs: {
        type: 'Array',
        name: '銷售品牌',
        input: 'select',
        multi: true,
        contents: [{
          type: 'ObjectId',
          reference: 'brands',
          refKeys: ['key', 'name'],
        }],
      },
      '@order': { type: 'Number', name: '排序' },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
    order: {
      key: {
        '@order': 1,
      },
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 300 },
        { path: 'brandRefs', width: 400 },
        { path: '@order', width: 100 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          ['key'],
          ['brandRefs'],
          ['@order'],
        ],
      },
    ],
  },
}
