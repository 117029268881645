const R = require('ramda')

const notEqPaths = require('coact/utils/notEqPaths')

module.exports = function shouldFindPrice (item, prevItem) {
  const type = R.path(['productRef', 'type'], item)

  const watched = (
    type === 'B' ? ['productRef', 'lensSpec.s', 'lensSpec.c'] : ['productRef']
  )

  const change = notEqPaths(R.__, item, prevItem)
  if (change(watched)) return true

  return false
}
