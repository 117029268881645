module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    priceRangeRef: {
      type: 'ObjectId',
      reference: 'priceRanges',
      label: '價格範圍代碼',
      refKeys: ['key'],
    },
    productRef: {
      type: 'ObjectId',
      reference: 'products',
      label: '商品代碼',
      refKeys: ['key'],
      required: true,
    },
    pricebookRef: {
      type: 'ObjectId',
      reference: 'pricebooks',
      label: '價格表',
      refKeys: ['key'],
      required: true,
    },
    price: {
      type: 'Number',
      label: '價格',
      required: true,
    },
    // createdAt: { type: 'Date', label: '建立時間' },
    // updatedAt: { type: 'Date', label: '修改時間' },
  },
}
