module.exports = {
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      label: '價格表名稱',
    },
    brandRef: {
      label: '品牌',
      type: 'ObjectId',
      reference: 'brands',
      refKeys: ['key', 'name'],
    },
    customerGroupRefs: {
      label: '客戶群',
      type: 'Array',
      input: 'select',
      multi: true,
      contents: [{
        type: 'ObjectId',
        reference: 'customerGroups',
        refKeys: ['key'],
      }],
    },
    supplierRefs: {
      label: '供應商',
      type: 'Array',
      input: 'select',
      multi: true,
      contents: [{
        type: 'ObjectId',
        reference: 'suppliers',
        refKeys: ['key', 'name'],
      }],
    },
    timeStart: {
      type: 'Date',
      label: '開始時間',
      format: 'YYYY/MM/DD',
    },
    timeEnd: {
      type: 'Date',
      label: '結束時間',
      format: 'YYYY/MM/DD',
    },
    type: {
      label: '類型',
      type: 'String',
      options: ['合約價', '牌價', '活動價', '上傳價', '進貨價'],
      input: 'select',
    },
    description: {
      type: 'String',
      label: '價格邏輯',
      input: 'textarea',
    },
    priority: {
      type: 'Number',
      label: '順序',
    },
    taxAdded: {
      type: 'Boolean',
      label: '含稅',
    },
  },
}
