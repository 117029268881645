const styleRow = {
  display: 'block!important',
  position: 'relative',
  width: '100%',
}
const styleCell = {
  display: 'inline-block!important',
  position: 'relative',
}
const borderColor = 'transparent'

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
    },
    '@media print': {
      '@page': {
        size: 'landscape',
        margin: 0,
      },
    },
  },
  print: {
    fontFamily: 'PMingLiU',
    fontWeight: 900,
    borderColor: 'transparent!important',
    fontSize: '14px',
    textAlign: 'left',
    '@global': {
      div: {
        display: 'inline-block',
        textAlign: 'inherit',
        overflow: 'hidden',
      },
    },
  },
  row: {
    display: 'flex!important',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  'print-data': {
    display: 'inline!important',
    verticalAlign: 'middle',
    overflow: 'visible!important',
  },

  /* debug 用 */
  'print-name': {
    display: 'inline!important',
    verticalAlign: 'middle',
    visibility: 'hidden',
  },

  'order-name': {
    position: 'absolute',
    display: 'block!important',
    top: 40,
    left: 345,
    width: 150,
    height: 45,
  },
  '@global [data-name="列印單名稱"]': {
    visibility: 'hidden',
    fontSize: '20px!important',
    display: 'block!important',
    position: 'relative',
    textAlign: 'center',
  },

  '@global [data-name="列印時間"]': {
    fontSize: '12px!important',
    display: 'block!important',
    // visibility: hidden;
    position: 'relative',
    textAlign: 'center',
  },

  /* 左上 */
  'header-left': {
    position: 'absolute',
    top: 38,
    left: 40,
    width: 500,

    display: 'flex!important',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',

    '&>div': {
      width: '100%',
    },

    '& [data-name="貨單號碼"]': { height: 21 },
    '& [data-name="客戶代號"]': { height: 25 },
    '& [data-name="客戶名稱"]': {
      height: 30,
      '& $print-data': {
        fontSize: '19px!important',
      },
    },
    '& [data-name="客戶地址"]': { height: 19 },
  },

  /* 右上 */
  'header-right': {
    position: 'absolute',
    top: 38,
    left: 553,
    width: 250,
    overflow: 'visible',

    display: 'flex!important',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',

    '&>div': {
      width: '100%',
    },

    '& [data-name="採購單號"]': { height: 21 },
    '& [data-name="出貨日期"]': { height: 21 },
    '& [data-name="專員"]': { marginTop: 5, height: 30 },
    '& [data-name="手機"]': { height: 21 },
  },

  /* 列表 */
  'order-list': {
    border: `2px solid ${borderColor}`,
    position: 'absolute',
    top: 138,
    left: 15,
  },

  'order-head': {
    ...styleRow,
    borderBottom: `1px solid ${borderColor}`,
    height: 43,

    '&>div': {
      paddingLeft: 3,
      paddingRight: 3,
      borderLeft: `1px solid ${borderColor}`,
      height: '100%',

      '&:first-child': {
        borderLeft: 'none',
      },
    },

    '& [data-name="客戶說明-name"]': {
      visibility: 'hidden',
      width: 65,
      lineHeight: '35px',
    },
    '& [data-name="客戶說明"]': {
      width: 535,
      lineHeight: '35px',
      fontSize: '18px!important',
    },
    '& [data-name="品牌名稱-name"]': {
      visibility: 'hidden',
      width: 22,
      lineHeight: '17px',
    },
    '& [data-name="品牌名稱"]': {
      width: 100,
      lineHeight: '35px',
      fontSize: '22px!important',
    },
  },
  'items-name': {
    ...styleRow,
    borderBottom: `1px solid ${borderColor}`,
    height: 19,

    '&>div': {
      ...styleCell,
      borderLeft: `1px solid ${borderColor}`,
      verticalAlign: 'top',
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '16px',
      height: '100%',

      '&:first-child': {
        borderLeft: 'none',
      },
    },
  },

  items: {
    height: 250,
    width: '100%',
  },

  item: {
    ...styleRow,
    borderBottom: `1px solid ${borderColor}`,
    height: 18,
    fontSize: '14px',

    '& [data-name="代碼"]': { fontSize: '11px!important', whiteSpace: 'nowrap', overflow: 'visible' },
    '& [data-name="單價"]': { textAlign: 'right' },
    '& [data-name="金額"]': { textAlign: 'right' },

    '&>div': {
      ...styleCell,
      borderLeft: `1px dotted ${borderColor}`,
      verticalAlign: 'top',
      lineHeight: '14px',
      height: '100%',
      paddingLeft: 2,
      paddingRight: 2,

      '&:first-child': {
        borderLeft: 'none',
      },
    },
  },

  '@global [data-name="代碼"]': { width: 64 },
  '@global [data-name="品名"]': { width: 220, overflow: 'visible' },
  '@global [data-name="規格"]': { width: 255 },
  '@global [data-name="訂製"]': { width: 30, textAlign: 'center' },
  '@global [data-name="左右"]': { width: 10, textAlign: 'center' },
  '@global [data-name="s"]': { width: 50, textAlign: 'right' },
  '@global [data-name="c"]': { width: 50, textAlign: 'right' },
  '@global [data-name="a"]': { width: 40, textAlign: 'center' },
  '@global [data-name="add"]': { width: 64, textAlign: 'center' },
  '@global [data-name="數量"]': { width: 31, textAlign: 'center' },
  '@global [data-name="單價"]': { width: 50 },
  '@global [data-name="金額"]': { width: 55, paddingRight: 20 },
  '@global [data-name="備註"]': { width: 95, marginLeft: 10 },

  foot: {
    ...styleRow,
    // border-top: 1px solid $border-color;
    height: 50,
    overflow: 'visible',

    '@global div': {
      border: 'none',
      height: '100%',
      verticalAlign: 'top',
    },

    '& [data-name="廣告"]': {
      width: 520,
      fontSize: '14px!important',
    },
    '& [data-name="合計-name"]': {
      visibility: 'hidden',
      lineHeight: '20px',
      textAlign: 'center',
      width: 20,
      borderLeft: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
    },
    '& [data-name="合計"]': {
      width: 75,
      paddingLeft: 2,
    },
    '& [data-name="總金額"]': {
      width: 50,
      textAlign: 'right',
    },
  },
}

export default styles
