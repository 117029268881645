const moment = require('moment')

module.exports = {
  title: '銷貨單資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        label: '貨單號碼',
        counter: {
          // A1903140001
          // key: ctx => `${ctx.values.seller.key}${moment(ctx.values.billDate).format('YYMMDD')}`,
          key: ctx => `${ctx.values.seller.key}${moment().format('YYMMDD')}`,
          value: (key, value) => `${key}${value.toString().padStart(4, 0)}`,
        },
      },
      date: {
        type: 'Date',
        label: '開單日期',
      },
      status: {
        type: 'String',
        label: '狀態',
        options: ['完成', '取消'],
        required: true,
      },
      paymentStatus: {
        type: 'String',
        label: '帳款狀態',
        options: ['未處理', '已結帳'], // to add '請款中'
      },
      type: {
        type: 'String',
        label: '型式',
        options: ['批量', '一般'],
      },
      orderType: {
        type: 'String',
        label: '貨單類別',
        options: ['銷售', '寄賣', '換片'],
        required: true,
      },
      stafferRef: {
        type: 'ObjectId',
        label: '打單客服',
        reference: 'staffers',
        refKeys: ['key', 'name'],
        required: true,
      },
      staffer: {
        type: 'Object',
        formatKeys: ['key', 'name'],
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
          name: { type: 'String' },
        },
      },
      orderStafferRef: {
        type: 'ObjectId',
        label: '接單人員',
        reference: 'staffers',
        refKeys: ['key', 'name'],
        required: true,
      },
      orderStaffer: {
        type: 'Object',
        label: '接單人員',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
          name: { type: 'String' },
        },
      },
      sellerRef: {
        type: 'ObjectId',
        reference: 'companies',
        refKeys: ['key', 'name'],
      },
      seller: {
        type: 'Object',
        label: '出貨公司',
        formatKeys: ['key', 'name'],
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
          name: { type: 'String' },
        },
      },
      custOrderNo: {
        type: 'String',
        label: '採購單號',
      },
      buyerRef: {
        type: 'ObjectId',
        label: '客戶',
        reference: 'customers',
        refKeys: ['key', 'name'],
        required: true,
        initFocus: true,
        populations: 'customerGroups,brands,salesRegions,staffers',
        populationdepth: 2,
      },
      buyer: {
        type: 'Object',
        label: '客戶',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String', label: '客戶代號' },
          name: { type: 'String', label: '客戶名稱' },
          shortName: { type: 'String', label: '客戶簡稱' },
          phone: { type: 'String', label: '客戶電話' },
          zip: { type: 'String', label: '客戶郵區' },
          address: { type: 'String', label: '客戶地址' },
          deliveryNote: { type: 'String', label: '客戶說明' },
          printPrice: {
            type: 'Boolean',
            label: '列印售價',
            formatter: value => (value ? '是' : '否'),
          },
        },
      },
      customerGroupRef: {
        type: 'ObjectId',
        label: '客戶群組',
        reference: 'customerGroups',
        refKeys: ['key'],
      },
      customerGroup: {
        type: 'Object',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String', label: '群組' },
        },
      },
      brandRef: { // 依據填入客戶帶出可選的品牌
        type: 'ObjectId',
        label: '品牌',
        reference: 'brands',
        refKeys: ['key', 'name'],
        required: true,
      },
      brand: {
        type: 'Object',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
          name: { type: 'String' },
        },
      },
      salesRegion: {
        type: 'Object',
        label: '業務區域',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String', label: '業務區域' },
          name: { type: 'String' },
        },
      },
      salesPerson: {
        type: 'Object',
        label: '業務代表',
        contents: {
          _id: { type: 'ObjectId' },
          key: { type: 'String' },
          name: { type: 'String', label: '業務代表' },
          phone: { type: 'String', label: '業務電話' },
        },
      },
      billDate: {
        type: 'Date',
        label: '結帳日期',
        format: 'YYYY/MM/DD',
        required: true,
      },
      amount: { type: 'Number', label: '金額' },
      summary: { type: 'String', label: '數量' },
      productRef: {
        type: 'ObjectId',
        label: '品名',
        reference: 'products',
        refKeys: ['key', 'name'],
      },
      product: {
        type: 'Object',
        contents: {
          _id: { type: 'ObjectId' },
          type: { type: 'String' },
          key: { type: 'String' },
          name: { type: 'String' },
          processType: { type: 'String' },
        },
      },
      itemsFile: {
        type: 'Object',
        label: '規格數量檔案',
        isFile: true,
        contents: {
          name: { type: 'String' },
          file: { type: 'LocalFile' },
        },
      },
      items: {
        type: 'Array',
        label: '項目',
        contents: [{ type: 'ObjectId', reference: 'salesItems' }],
      },
    },
  },
  index: {
    primary: {
      key: { key: 1 },
      unique: true,
    },
    type: {
      key: { type: 1, buyerRef: 1 },
    },
    createdAt: {
      key: { '@@createdAt': 1 },
    },
    buyer: {
      key: { buyerRef: 1, '@@createdAt': -1 },
    },
    billDate: {
      key: { billDate: 1, key: 1 },
    },
    dailyReport: {
      key: { orderType: 1, status: 1, billDate: 1, key: 1 },
    },
    groupReport: {
      key: { orderType: 1, status: 1, billDate: 1, customerGroupRef: 1, key: 1 },
    },
    custReport: {
      key: { orderType: 1, status: 1, billDate: 1, buyerRef: 1, key: 1 },
    },
  },
}
