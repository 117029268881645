const schema = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    title: { type: 'String', label: '盤點名稱' },
    note: { type: 'String', label: '註記' },
    summary: { type: 'String', label: '概述' },
    status: { type: 'String', label: '狀態', options: ['完成', '取消'] },
    company: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '盤點公司', reference: 'companies', refKeys: ['key', 'name'] },
        key: { type: 'String' },
        name: { type: 'String' },
      },
    },
    date: { type: 'Date', label: '盤點日期' },
    zeroStocksNotInTheFile: { type: 'Boolean', label: '清空不在檔案中的庫存' },
    checkFile: {
      type: 'Object',
      label: '盤點檔案',
      isFile: true,
      contents: {
        name: { type: 'String' },
        file: { type: 'LocalFile' },
      },
    },
    /*
    lines: {
      type: 'Array',
      contents: [{
        type: 'Object',
        contents: {
          product: {
            type: 'Object',
            contents: {
              id: { type: 'ObjectId', label: '品項', reference: 'products', refKeys: ['key'] },
              key: { type: 'String', label: '品項編碼' },
              name: { type: 'String', label: '品項名' },
            },
          },
          checkFile: {
            type: 'Object',
            label: '盤點檔案',
            isFile: true,
            contents: {
              name: { type: 'String' },
              file: { type: 'LocalFile' },
            },
          },
        },
      },
      ],
    },
    */
    items: {
      type: 'Array',
      contents: [{
        type: 'Object',
        contents: {
          product: {
            type: 'Object',
            contents: {
              id: { type: 'ObjectId', label: '品項', reference: 'products', refKeys: ['key'] },
              key: { type: 'String', label: '品項編碼' },
              name: { type: 'String', label: '品項名' },
            },
          },
          time: { type: 'Date', label: '盤點時間' },
          s: { type: 'Number', label: 'S' },
          c: { type: 'Number', label: 'C' },
          qty: { type: 'Number', label: 'Qty' },
        },
      },
      ],
    },
  },
}

module.exports = schema
