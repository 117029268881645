const S = require('coact/saga/utils')
const mapWithSchema = require('coact/utils/schema/mapWithSchema')
const adaptReceivedData = require('coact/saga/utils/adaptReceivedData').default

const schema = require('../schema')

module.exports = function* initDoc () {
  const api = yield S.getContext('api')

  const res = yield S.call(
    api.get,
    {
      url: 'api/model/purchaseOrders/view/general',
      search: { initDoc: null },
    },
  )

  return mapWithSchema(schema, adaptReceivedData, res.doc)
}
