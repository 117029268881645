const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const schema = require('./schema')
const initDoc = require('./initDoc')
const autofill = require('./autofill')
const computer = require('./computer')
const setBrandOptions = require('./setBrandOptions')
const watchItemForPrice = require('./watchItemForPrice')
const checkBillDate = require('./checkBillDate')

const SalesEditor = require('./SalesEditor').default


const save = function* (payload, ctx) {
  const result = checkBillDate(ctx.doc)

  if (!result.ok) {
    if (result.error) {
      // eslint-disable-next-line no-alert
      window.alert(result.error.message)
      return null
    }
    if (result.alert) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(result.alert.message)) {
        return null
      }
    }
  }
  const savedData = yield ctx.S.call(editEffects.save)
  return savedData
}

module.exports = {
  title: '銷貨單',
  schema,
  edit: {
    creator: {
      date: '$$NOW',
      billDate: '$$NOW',
      status: '完成',
      paymentStatus: '未處理',
      orderType: '銷售',
      'items.0.qty': 1,
      'items.0.lensSpec.s': 0,
      'items.0.lensSpec.c': 0,
    },
    noclone: {
      'items.0.lensSpec.eye': true,
      'items.0.lensSpec.s': true,
      'items.0.lensSpec.c': true,
      'items.0.lensSpec.a': true,
      'items.0.lensSpec.add': true,
      'items.0.spec': true,
      'items.0.priceRange': true,
      'items.0.priceBook': true,
      'items.0.unitPrice': true,
      'items.0.bookPrice': true,
      'items.0.price': true,
      'items.0.qty': true,
      'items.0.subtotal': true,
      'items.0.discount': true,
    },
    autofill,
    computer,
    watcher: {
      buyerRef: { name: 'setBrandOptions' },
      'items.0': { name: 'watchItemForPrice', run: 'latest' },
    },
    actions: {
      saveAndCopy: {
        authChecker: () => false,
      },
      saveAndPrint: {
        text: '列印',
        effect: { name: 'saveAndPrint' },
        props: { variant: 'contained', color: 'primary' },
        hotKey: 'ctrl+p',
        preventDefault: true,
      },
      saveAndCreate: {
        text: '儲存後開新單',
        hotKey: 'ctrl+b',
      },
      savePrintAndCreate: {
        text: '列印後開新單',
        effect: { name: 'savePrintAndCreate' },
        props: { variant: 'contained', color: 'primary' },
        hotKey: 'shift+enter',
      },
    },
    effect: {
      setBrandOptions,
      watchItemForPrice,
      save,
      savePrintAndCreate: function* savePrintAndCreate (payload, ctx) {
        const savedData = yield save(payload, ctx)
        if (savedData) {
          window.print()
          ctx.history.push('/edit/sales/view/user')
        }
      },
      saveAndPrint: function* saveAndPrint (payload, ctx) {
        const savedData = yield save(payload, ctx)
        if (savedData) {
          window.print()
        }
      },
    },
    initDoc,
    disabled: {
      key: true,
      date: true,
      stafferRef: true,
      seller: true,
      'salesRegion.key': true,
      'salesPerson.name': true,
      'salesPerson.phone': true,
      'buyer.name': true,
      'buyer.address': true,
      'customerGroup.key': true,
      'buyer.printPrice': true,
      amount: true,
      summary: true,
      'items.0.subtotal': true,
      'items.0.unitPrice': true,
    },
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'status', 'paymentStatus'],
          ['stafferRef', 'seller', 'salesRegion.key', 'salesPerson.name', 'salesPerson.phone'],
          ['buyer.name', 'buyer.address', 'customerGroup.key', 'buyer.printPrice'],
          [{ path: 'buyerRef', span: 2 }, 'custOrderNo', 'billDate', 'orderStafferRef'],
          ['brandRef', 'orderType', 'amount', 'summary'],
          [{ path: 'buyer.deliveryNote', multiline: true, span: 4 }],
        ],
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        limit: 14,
        fields: [
          { path: 'productRef', width: 300, sort: null },
          { path: 'lensSpec.eye', width: 60 },
          { path: 'lensSpec.s', width: 60, inputType: 'number', step: 0.25 },
          { path: 'lensSpec.c', width: 60, inputType: 'number', step: 0.25 },
          { path: 'lensSpec.a', width: 50, inputType: 'number' },
          { path: 'lensSpec.add', width: 60, inputType: 'number', step: 0.25 },
          { path: 'spec', width: 290, span: 5 },
          { path: 'unitPrice', width: 50 },
          { path: 'qty', width: 50 },
          { path: 'subtotal', width: 50 },
          { path: 'discount', width: 50, tabIndex: -1 },
          { path: 'note', width: 100 },
        ],
        deletable: true,
        copyTabIndex: 0,
      },
    },
    Components: {
      PageForm: SalesEditor,
    },
    populations: 'staffers,customers,brands,customerGroups,companies,products,salesItems',
    populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        'key', 'custOrderNo', 'stafferRef', 'date', 'billDate',
        'customerGroupRef', 'buyerRef', 'brandRef', 'seller',
        'orderType', 'summary', 'status', 'paymentStatus',
      ],
    },
    populations: 'staffers,customers,brands,customerGroups,companies',
  },
}
