module.exports = {
  title: '人員資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        unique: true,
        name: '人員編號',
      },
      name: {
        type: 'String',
        name: '姓名',
      },
      type: {
        type: 'String',
        name: '類別',
        options: ['客服', '業務', '會計', '資訊', '公司', '玻璃', '主管'],
      },
      state: {
        type: 'String',
        name: '狀態',
        options: ['在職', '離職'],
      },
      companyRef: {
        type: 'ObjectId',
        reference: 'companies',
        name: '所屬公司',
        refKeys: ['name'],
      },
      phone: {
        type: 'String',
        name: '電話',
      },
      userRef: {
        type: 'ObjectId',
        reference: 'users',
        name: '使用帳號',
        refKeys: ['account'],
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key' },
        { path: 'name' },
        { path: 'type' },
        { path: 'state' },
        { path: 'companyRef' },
        { path: 'phone', width: 120 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        // cols: { default: 500 },
        fields: [
          [{ path: 'key' }, { path: 'type' }, { path: 'state' }],
          [{ path: 'name' }, { path: 'phone' }],
          [{ path: 'companyRef' }],
          [{ path: 'userRef' }],
        ],
      },
    ],
  },
}
