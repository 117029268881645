module.exports = {
  title: '銷貨項目',
  list: {
    ui: {
      fields: [
        { path: 'orderRef.key', model: 'sales', queryPath: 'orderRef' },
        { path: 'orderRef.date', model: 'sales', queryPath: 'orderRef' },
        { path: 'orderRef.orderType', model: 'sales', queryPath: 'orderRef' },
        { path: 'orderRef.status', model: 'sales', queryPath: 'orderRef' },
        { path: 'orderRef.buyerRef', model: 'sales', queryPath: 'orderRef' },
        'productRef',
        'lensSpec.s',
        'lensSpec.c',
        'lensSpec.add',
        // 'price',
        'qty',
        'unitPrice',
        'discount',
        'note',
        { path: 'orderRef.buyer.deliveryNote', model: 'sales', queryPath: 'orderRef' },
      ],
    },
    populationdepth: 2,
    populations: 'sales,products,customers',
  },
  edit: {
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['productRef'],
          ['lensSpec.s', 'lensSpec.c', 'lensSpec.add'],
          ['qty', 'unitPrice', 'discount', 'note'],
        ],
      },
    },
  },
}
