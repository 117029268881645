module.exports = [
  {
    category: '客戶/供應商管理',
    features: ['customers', 'customerGroups', 'salesRegions', 'suppliers'],
  },
  {
    category: '基本資料管理',
    features: ['staffers', 'companies'],
  },
  {
    category: '鏡片資料管理',
    features: ['brands', 'products', 'lensRefractions', 'lensDesigns', 'lensProgDists', 'lensBlanks', 'lensCoatings'],
  },
  {
    category: '價格管理',
    features: ['priceRanges', /* 'rangeEditor', */'pricebooks', 'prices', 'prices:lookup', 'prices:wholesale'],
  },
  {
    category: '銷售管理',
    features: ['sales:user', 'sales:batch', 'salesItems'/* 'export.salesOrders', 'export.salesOrderReport' */],
  },
  {
    category: '採購管理',
    features: ['purchaseOrders:general', 'purchaseOrders:batch', 'stockInLines:line'],
  },
  {
    category: '庫存管理',
    features: ['stockState', 'stockChecks:form', 'stockInfos'],
  },
  {
    category: '資料輸出',
    features: ['externalProducts', 'custExchanger', 'salesReport'],
  },
  /*
  {
    category: '系統',
    features: ['uploadTemplate'],
  },
  */
]
