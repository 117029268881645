const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const StockState = require('./StockState')
const saga = require('./StockState/saga')


const routeConfig = R.assocPath(
  ['routes', 'stockState'],
  {
    title: '庫存查詢',
    path: '/route/stockState',
    component: StockState,
  },
  { },
)

const sagaConfig = R.assocPath(
  ['sagas'],
  {
    stockState: saga,
  },
  { },
)

const config = mergeConfig(routeConfig, sagaConfig)


module.exports = fullConfig => mergeConfig(fullConfig, config)
