module.exports = {
  ui: {
    order: {
      name: 'formEditor',
      fields: [
        [{ path: 'title', span: 2 }, 'status'],
        ['date', 'company.id'],
        [{ path: 'note', span: 2, multiline: true }],
        [{ path: 'summary', span: 2 }],
        ['checkFile'],
      ],
    },
    /*
    lines: {
      name: 'tableEditor',
      path: 'lines',
      fields: [
        'product.id',
        'checkFile',
      ],
      copyable: false,
    },
    */
    /*
    items: {
      name: 'tableEditor',
      path: 'items',
      fields: [
        'product.id',
        's',
        'c',
        'qty',
      ],
      copyable: false,
    },
    */
  },
  creator: {
    status: '完成',
    date: '$$NOW',
  },
  required: {
    date: true,
    'company.id': true,
  },
  project: {
    items: 0,
  },
}
