const R = require('ramda')

const mergeDeep = require('coact/utils/mergeDeep')
const S = require('coact/saga/utils')
const $auth = require('coact/store/auth')

const config = {
  creator: {
    date: '$$NOW',
    billDate: '$$NOW',
    status: '完成',
    paymentStatus: '未處理',
    type: '批量',
    orderType: '銷售',
  },
  noclone: {
    key: true,
    date: true,
    status: true,
  },
  autofill: {
    '': [
      {
        src: 'buyerRef',
        fill: (buyerRef) => {
          const {
            _id, key, name, shortName, phone, zip, address, deliveryNote, printPrice,
            groupRef: customerGroupRef,
            salesRegionRef: salesRegion,
            salesPersonRef: salesPerson,
          } = buyerRef

          const buyer = {
            // eslint-disable-next-line object-property-newline
            _id, key, name, shortName, phone, zip, address, deliveryNote, printPrice,
          }

          return {
            buyer,
            customerGroupRef,
            customerGroup: customerGroupRef,
            salesRegion,
            salesPerson,
          }
        },
      },
      {
        src: 'brandRef',
        fill: (brandRef) => {
          const brand = R.pick(['_id', 'key', 'name'], brandRef)

          return { brand }
        },
      },
      {
        src: 'stafferRef',
        fill: (stafferRef) => {
          const staffer = R.pick(['_id', 'key', 'name'], stafferRef)

          const { companyRef } = stafferRef

          return {
            staffer,
            sellerRef: companyRef,
            orderStafferRef: stafferRef,
          }
        },
      },
      {
        src: 'sellerRef',
        fill: (sellerRef) => {
          const seller = R.pick(['_id', 'key', 'name'], sellerRef)

          return { seller }
        },
      },
      {
        src: 'orderStafferRef',
        fill: (orderStafferRef) => {
          const orderStaffer = R.pick(['_id', 'key', 'name'], orderStafferRef)

          return { orderStaffer }
        },
      },
      {
        src: 'productRef',
        fill: [
          { from: '', to: 'product', or: null },
        ],
      },
    ],
  },
  watcher: {
    buyerRef: { name: 'setBrandOptions' },
  },
  effect: {
    setBrandOptions: function setBrandOptions (spec, ctx) {
      const { value } = spec
      const { dispatch, $options } = ctx

      dispatch($options.act.loadOptions({
        optionsKey: 'brands',
        options: R.pathOr([], ['groupRef', 'brandRefs'], value),
        shouldFetchFurther: false,
      }))
    },
  },
  initDoc: function* initDoc (doc) {
    const store = yield S.getContext('store')
    const api = yield S.getContext('api')

    const user = $auth.select.user(store.getState())

    const { data: staffers } = yield S.call(
      api.get,
      {
        url: 'api/model/staffers',
        search: {
          query: {
            userRef: `ObjectId('${user._id}')`,
          },
          populationdepth: 1,
          populations: 'companies',
        },
      },
    )

    return {
      ...doc,
      stafferRef: staffers[0],
    }
  },
}

module.exports = function addAuto (pageConfig) {
  return mergeDeep(pageConfig, config)
}
