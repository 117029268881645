const mergeConfig = require('coact/config/mergeConfig')
const mergeDeep = require('coact/utils/mergeDeep')

const productSchema = require('~/src/models/products').schema

const serverSchema = require('./schema')


const clientSchema = {
  contents: {
    productRef: productSchema,
  },
}

const schema = mergeDeep(serverSchema, clientSchema)

const priceLookupView = {
  title: '價格查詢',
  schema,
  list: {
    ui: {
      fields: [
        { path: 'priceRangeRef', width: 150 },
        { path: 'productRef.key', model: 'products', queryPath: 'productRef' },
        { path: 'productRef.name', model: 'products', queryPath: 'productRef' },
        { path: 'pricebookRef', width: 250 },
        { path: 'price' },
        { path: 'createdAt' },
        { path: 'updatedAt' },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['priceRangeRef'],
          ['productRef.key', 'productRef.name'],
          ['pricebookRef'],
          ['price'],
        ],
      },
    ],
  },
}

const config = {
  models: {
    prices: {
      views: {
        lookup: priceLookupView,
      },
    },
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
