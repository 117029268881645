const {
  compose,
  withProps,
  withHandlers,
} = require('recompose')

const { connect } = require('react-redux')

const actions = require('./actions')

const schema = {
  type: 'Object',
  contents: {
    productId: {
      type: 'ObjectId',
      reference: 'products',
      label: '品名',
      refKeys: ['key'],
    },
    companyId: {
      type: 'ObjectId',
      reference: 'companies',
      label: '公司',
      refKeys: ['name'],
    },

    data: {
      type: 'Object',
      contents: {
        scope: {
          type: 'Object',
          contents: {
            sMax: { type: 'Number' },
            sMin: { type: 'Number' },
            cMax: { type: 'Number' },
            cMin: { type: 'Number' },
          },
        },
        stocks: {
          type: 'Array',
          contents: [{
            type: 'Object',
            contents: {
              s: { type: 'Number' },
              c: { type: 'Number' },
              qty: { type: 'Number' },
            },
          }],

        },
      },
    },
  },
  watcher: { name: 'findStocks' },
}

const container = compose(
  connect(),

  withProps({ schema }),

  withHandlers({
    download: ({ dispatch }) => () => dispatch(actions.download()),
  }),

)

module.exports = container
