const mergeDeep = require('coact/utils/mergeDeep')

const uiConfig = {
  ui: {
    order: {
      name: 'formEditor',
      fields: [
        ['key', 'date', 'status', 'paymentStatus', 'type'],
        ['stafferRef', 'seller', 'salesRegion.key', 'salesPerson.name', 'salesPerson.phone'],
        ['buyer.name', 'buyer.address', 'customerGroup.key', 'buyer.printPrice'],
        [{ path: 'buyerRef', span: 2 }, 'custOrderNo', 'billDate', 'orderStafferRef'],
        ['brandRef', 'orderType', 'amount', 'summary'],
        [{ path: 'buyer.deliveryNote', multiline: true, span: 4 }],
        [{ path: 'productRef', span: 2 }, 'itemsFile'],
      ],
    },
  },
  disabled: {
    key: true,
    date: true,
    type: true,
    stafferRef: true,
    seller: true,
    'salesRegion.key': true,
    'salesPerson.name': true,
    'salesPerson.phone': true,
    'buyer.name': true,
    'buyer.address': true,
    'customerGroup.key': true,
    'buyer.printPrice': true,
    amount: true,
    summary: true,
    'items.0.subtotal': true,
    'items.0.unitPrice': true,
  },
}

module.exports = function addUI (pageConfig) {
  return mergeDeep(pageConfig, uiConfig)
}
