const moment = require('moment')

const header = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: {
      type: 'String',
      label: '進貨單號',
      counter: {
        // PO1903140001
        key: () => `PO${moment().format('YYMMDD')}`,
        value: (key, value) => `${key}${value.toString().padStart(4, 0)}`,
      },
    },
    keyOfSellerSide: { type: 'String', label: '供應端單號' },
    status: { type: 'String', label: '狀態', options: ['完成', '取消'] },
    date: { type: 'Date', label: '開單日期' },
    billDate: {
      type: 'Date',
      label: '結帳日期',
      // format: 'YYYY/MM/DD',
      required: true,
    },
    type: { type: 'String', label: '貨單類別', options: ['批量', '一般'] },
    orderType: { type: 'String', label: '貨單類別', options: ['一般', '寄賣'], required: true },
    stafferRef: {
      type: 'ObjectId',
      label: '打單客服ID',
      reference: 'staffers',
      refKeys: ['key', 'name'],
      required: true,
    },
    staffer: {
      type: 'Object',
      label: '打單客服',
      formatKeys: ['key', 'name'],
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String' },
        name: { type: 'String' },
      },
    },
    buyerRef: {
      type: 'ObjectId',
      label: '進貨公司ID',
      reference: 'companies',
      refKeys: ['key', 'name'],
    },
    buyer: {
      type: 'Object',
      label: '進貨公司',
      formatKeys: ['key', 'name'],
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String' },
        name: { type: 'String' },
      },
    },
    sellerRef: {
      type: 'ObjectId',
      label: '供應商',
      reference: 'suppliers',
      refKeys: ['key', 'name'],
      required: true,
      // autoFocus: true,
    },
    seller: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String', label: '供應商代號' },
        name: { type: 'String', label: '供應商名稱' },
        shortName: { type: 'String', label: '供應商簡稱' },
        phone: { type: 'String', label: '供應商電話' },
        zip: { type: 'String', label: '供應商郵區' },
        address: { type: 'String', label: '供應商地址' },
      },
    },
    amount: { type: 'Number', label: '總金額' },
    summary: { type: 'String', label: '數量合計' },
    note: { type: 'String', label: '備註' },

    // for batch view
    productRef: {
      type: 'ObjectId',
      label: '品名',
      reference: 'products',
      refKeys: ['key', 'name'],
    },
    product: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        type: { type: 'String' },
        key: { type: 'String', label: '商品代碼' },
        name: { type: 'String' },
        processType: { type: 'String' },
      },
    },
    itemsFile: {
      type: 'Object',
      label: '規格數量檔案',
      isFile: true,
      contents: {
        name: { type: 'String' },
        file: { type: 'LocalFile' },
      },
    },
  },
}

const line = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    seq: { type: 'Number' },
    productRef: {
      type: 'ObjectId',
      label: '品名',
      reference: 'products',
      refKeys: ['key', 'name'],
    },
    product: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        type: { type: 'String' },
        key: { type: 'String' },
        name: { type: 'String' },
        processType: { type: 'String' },
      },
    },
    spec: { type: 'String', label: '規格' },
    lensSpec: {
      label: '鏡片規格',
      type: 'Object',
      contents: {
        eye: { type: 'String', label: 'LR', options: ['L', 'R'] },
        s: { type: 'Number', label: 'S', format: '-0.00' },
        c: { type: 'Number', label: 'C', format: '-0.00' },
        a: { type: 'Number', label: 'A' },
        add: { type: 'Number', label: 'ADD', format: '-0.00' },
      },
    },
    priceRange: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String' },
        note: { type: 'String' },
      },
    },
    pricebook: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String' },
      },
    },
    unitPrice: { type: 'Number', label: '單價' },
    bookPrice: { type: 'Number', label: '表價' },
    price: { type: 'Number', label: '計價' },
    qty: { type: 'Number', label: '數量' },
    subtotal: { type: 'Number', label: '小計' },
    note: { type: 'String', label: '項目備註' },
    // parser: function discountParser (value) {
    //   if (!value) return null
    //   if (+value > 1) return +`0.${value.match(/\d+/g).join('')}`
    //   return +value
    // },
    // discount: { type: 'Number', label: '折數' },
  },
}

module.exports = {
  header,
  line,
}
