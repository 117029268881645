import * as R from 'ramda'
import React from 'coact/react'
import { object } from 'coact/prop-types'
import moment from 'coact/moment'
import numeral from 'coact/numeral'
import classNames from 'classnames/bind'

function formatDegree (value) {
  if (value === 0) return '-0.00'
  return numeral(value).format('+0.00')
}

function formatDiscount (value) {
  if (value) {
    const m = value.toString().match(/0\.(\d+)/)
    if (Array.isArray(m) && m[1]) return `${m[1]}折`
  }
  return ''
}


function Print (props) {
  const { doc, classes: style } = props
  const cx = classNames.bind(style)
  const orderName = '出　貨　單'
  const formattedKey = typeof doc.key === 'string' ? doc.key.replace(/(.{7})/, '$1 ') : ''

  const updatedTimeString = moment(doc['@@updatedAt']).isValid
    ? moment(doc['@@updatedAt']).format('YYYY/M/D HH:mm:ss')
    : ''

  const orderDateString = moment(doc.date).isValid
    ? moment(doc.date).format('YYYY/M/D')
    : ''

  const deliveryNote = R.path(['buyer', 'deliveryNote'], doc)

  const typeNote = doc.orderType === '銷售' ? '' : ` (${doc.orderType})`

  return (
    <div className={style.print}>
      <div className={style['header-left']}>
        <div data-name="貨單號碼" className={style.row}>
          <div className={style['print-name']}>貨單號碼：</div>
          <div className={style['print-data']}>{formattedKey + typeNote}</div>
        </div>
        <div data-name="客戶代號" className={style.row}>
          <div className={style['print-name']}>客戶代號：</div>
          <div className={style['print-data']}>{doc.buyer && doc.buyer.key}</div>
        </div>
        <div data-name="客戶名稱" className={style.row}>
          <div className={style['print-name']}>客戶名稱：</div>
          <div className={style['print-data']}>{doc.buyer && doc.buyer.name}</div>
        </div>
        <div data-name="客戶地址" className={style.row}>
          <div className={style['print-name']}>客戶地址：</div>
          <div className={style['print-data']}>
            {doc.buyer && doc.buyer.zip}{doc.buyer && doc.buyer.address}
             {doc.buyer && doc.buyer.phone}
          </div>
        </div>
      </div>
      <div className={style['header-right']}>
        <div data-name="採購單號" className={style.row}>
          <div className={style['print-name']}>採購單號：</div>
          <div className={style['print-data']}>{doc.custOrderNo}</div>
        </div>
        <div data-name="出貨日期" className={style.row}>
          <div className={style['print-name']}>出貨日期：</div>
          <div className={style['print-data']}>{orderDateString}</div>
        </div>
        <div data-name="專員" className={style.row}>
          <div className={style['print-name']}>專　　員：</div>
          <div className={style['print-data']}>{doc.salesPerson && doc.salesPerson.name}-{doc.orderStaffer && doc.orderStaffer.key}</div>
        </div>
        <div data-name="手機" className={style.row}>
          <div className={style['print-name']}>手　　機：</div>
          <div className={style['print-data']}>{doc.salesPerson && doc.salesPerson.phone}</div>
        </div>
      </div>
      <div className={style['order-name']}>
        <div data-name="列印單名稱">{orderName}</div>
        <div data-name="列印時間">{updatedTimeString}</div>
      </div>
      <div className={style['order-list']}>
        <div className={style['order-head']}>
          <div data-name="客戶說明-name">客戶說明</div>
          <div data-name="客戶說明">{deliveryNote}</div>
          <div data-name="品牌名稱-name">品牌</div>
          <div data-name="品牌名稱">{doc.brand && doc.brand.key}</div>
        </div>
        <div className={style['items-name']}>
          <div className={style['print-name']} data-name="代碼">代　碼</div>
          <div className={style['print-name']} data-name="品名">品　　名</div>
          <div className={style['print-name']} data-name="規格">規　　格</div>
          <div className={style['print-name']} data-name="數量">數量</div>
          <div className={style['print-name']} data-name="單價">單價</div>
          <div className={style['print-name']} data-name="金額">金額</div>
          <div className={style['print-name']} data-name="備註">備註</div>
        </div>
        <div className={style.items}>
          {doc.items && doc.items.map((item, i) => {
            if (item.product) {
              const isTypeE = item.product.type === 'E'
              const { product, lensSpec } = item

              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')
              ctx.font = '14px PMingLiU'
              let nameSize = 14
              if (ctx.measureText(product.name).width > 210) {
                nameSize = 13
              }

              return (
                <div className={style.item} key={i}>
                  <div data-name="代碼" className={cx({ 'text-right': isTypeE })}>
                    {product.key}
                  </div>
                  <div data-name="品名" className={cx({ 'text-right': isTypeE })} style={{ fontSize: nameSize }}>
                    {product.name}
                  </div>
                  {product.type === 'B' ?
                    <div data-name="規格">
                      <div data-name="訂製">
                        {(typeof product.key === 'string' && product.key.search(/R$/) >= 0) ?
                          '訂製' : ''}
                      </div>
                      <div data-name="左右">{lensSpec.eye}</div>
                      <div data-name="s">{`S${formatDegree(lensSpec.s)}`}</div>
                      <div data-name="c">{`C${formatDegree(lensSpec.c)}`}</div>
                      <div data-name="a">{lensSpec.a ? `A${lensSpec.a}` : ''}</div>
                      <div data-name="add">
                        {lensSpec.add ? `ADD${formatDegree(lensSpec.add)}` : ''}
                      </div>
                    </div>
                  :
                    <div data-name="規格"> {item.spec} </div>
                  }
                  <div data-name="數量">{item.qty}</div>
                  <div data-name="單價">{(doc.buyer && doc.buyer.printPrice) ? item.unitPrice : ''}</div>
                  <div data-name="金額">{(doc.buyer && doc.buyer.printPrice) ? item.subtotal : ''}</div>
                  <div data-name="備註">{formatDiscount(item.discount)} {item.note}</div>
                </div>
              )
            }
            return null
          })}
        </div>
        <div className={style.foot}>
          <div data-name="廣告"></div>
          <div data-name="合計-name">合計</div>
          <div data-name="合計">{doc.summary}</div>
          <div data-name="總金額">{(doc.buyer && doc.buyer.printPrice) ? doc.amount : ''}</div>
        </div>
      </div>
    </div>
  )
}

Print.propTypes = {
  doc: object,
  classes: object.isRequired,
}

export default Print
