const buildQueryString = require('coact/utils/api/buildQueryString')
const { builtinEffects: editEffects } = require('coact/saga/editSaga')


module.exports = {
  actions: {
    saveAndCopy: {
      authChecker: () => false,
    },
    downloadXlsx: {
      text: '下載進貨單',
      effect: { name: 'downloadXlsx' },
      props: { variant: 'contained', color: 'primary' },
      hotKey: 'ctrl+p',
      preventDefault: true,
    },
    saveAndCreate: {
      text: '儲存後開新單',
      hotKey: 'ctrl+b',
    },
  },
  effect: {
    downloadXlsx: function* saveAndPrint (payload, ctx) {
      const savedData = yield ctx.S.call(editEffects.save)
      if (savedData) {
        const { key, seller } = savedData
        const query = {
          id: savedData._id,
          fullname: `${key}-${seller.key}-${seller.name}.xlsx`,
        }
        window.open(
          `api/print/batchPurchase/xlsx?${buildQueryString(query)}`,
          '_blank',
        )
      }
    },
  },
}
