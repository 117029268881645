const S = require('coact/saga/utils')

const $auth = require('coact/store/auth')

module.exports = function* initDoc (doc) {
  const store = yield S.getContext('store')
  const api = yield S.getContext('api')

  const user = $auth.select.user(store.getState())

  const { data: staffers } = yield S.call(
    api.get,
    {
      url: 'api/model/staffers',
      search: {
        query: {
          userRef: `ObjectId('${user._id}')`,
        },
        populationdepth: 1,
        populations: 'companies',
      },
    },
  )

  return {
    ...doc,
    stafferRef: staffers[0],
  }
}
