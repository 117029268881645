const initDoc = require('./initDoc')

module.exports = {
  initDoc,
  creator: {
    'lines.0.qty': 1,
    'lines.0.lensSpec.s': 0,
    'lines.0.lensSpec.c': 0,
  },
  noclone: {
    'lines.0._id': true,
    'lines.0.lensSpec.eye': true,
    'lines.0.lensSpec.s': true,
    'lines.0.lensSpec.c': true,
    'lines.0.lensSpec.a': true,
    'lines.0.lensSpec.add': true,
    'lines.0.spec': true,
    'lines.0.priceRange': true,
    'lines.0.priceBook': true,
    'lines.0.bookPrice': true,
    'lines.0.price': true,
    'lines.0.qty': true,
    'lines.0.subtotal': true,
  },
}
