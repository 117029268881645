module.exports = {
  title: '折射率資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        name: '折射率代碼',
      },
      name: {
        type: 'String',
        name: '折射率名稱',
      },
      refraction: {
        type: 'String',
        name: '折射率',
      },
      material: {
        type: 'String',
        name: '鏡片材料',
      },
    },
  },
  index: {
    primary: {
      key: {
        name: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 100 },
        { path: 'name', width: 200 },
        { path: 'refraction' },
        { path: 'material', width: 90 },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        cols: { default: 500 },
        fields: [
          [{ path: 'key' }],
          [{ path: 'name' }],
          [{ path: 'refraction' }],
          [{ path: 'material' }],
        ],
      },
    ],
  },
}
