const schema = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    qty: { type: 'Number', label: '數量' },
    stock: {
      type: 'Object',
      contents: {
        productId: { type: 'ObjectId', label: '商品', reference: 'products', refKeys: ['key'] },
        warehouseId: { type: 'ObjectId', label: '公司', reference: 'companies', refKeys: ['key', 'name'] },
        s: { type: 'Number', label: 'S' },
        c: { type: 'Number', label: 'C' },
      },
    },
  },
}

module.exports = schema
